@charset "utf-8";
/*!
 * https://jo-so.de/main.css
 * @copyright 2017–2021 Jörg Sommer <joerg@jo-so.de>
 * @license GPL-3.0-or-later
 */

/* monospace font */
@import "@fontsource/inconsolata/variable.css";
/* text font with serifs */
@import "@fontsource/crimson-pro/variable.css";
@import "@fontsource/crimson-pro/variable-italic.css";

/* text font without serifs */
@font-face {
  font-family: WorkSans;
  src: local("WorkSans-VariableFont"),
    local("WorkSans VariableFont"),
    local("WorkSansVariableFont"),
    local("WorkSans"),
    local("Work Sans-VariableFont"),
    local("Work Sans VariableFont"),
    local("Work SansVariableFont"),
    local("Work Sans"),
    url(WorkSans-VariableFont_wght.woff2) format("woff2"),
    url(WorkSans-VariableFont_wght.ttf) format("ttf");
}
@font-face {
  font-family: WorkSans;
  src: local("WorkSans-Italic-VariableFont"),
    local("WorkSans Italic VariableFont"),
    local("WorkSansItalicVariableFont"),
    local("WorkSans-Italic"),
    local("WorkSans Italic"),
    local("WorkSansItalic"),
    local("Work Sans-Italic-VariableFont"),
    local("Work Sans Italic VariableFont"),
    local("Work SansItalicVariableFont"),
    local("Work Sans-Italic"),
    local("Work Sans Italic"),
    local("Work SansItalic"),
    url(WorkSans-Italic-VariableFont_wght.woff2) format("woff2"),
    url(WorkSans-Italic-VariableFont_wght.ttf) format("ttf");
  font-style: italic;
}

body {
  background-color: #F9F7EC;
  color: #383838;
  margin: 0px;

  box-sizing: border-box;
  height: 100vh;

  display: flex;
  flex-direction: column;
}

#content-wrapper {
  flex-grow: 1;
  padding-top: 56px;

  display: flex;
  flex-direction: row;
}

#content-wrapper > *:first-child {
  flex-grow: 1;
}

#content-wrapper > *:last-child {
  flex-grow: 6;
}

#header,
#content,
#footer {
  padding: 8px 12px;
}

/*
 * Page header and footer
 */
#header,
#footer {
  background-color: #187FB0;
  background-image: radial-gradient(ellipse at 50% 100%, #135F90, #023F79 70%);
  color: #E3AC59;
}

#header a,
#footer a {
  color: inherit;
}

#header {
  font-family: WorkSans, Arial, sans-serif;
  font-size: 18px;
  font-variant-caps: small-caps;
  font-variation-settings: 'wght' 350;
  letter-spacing: -0.5px;

  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 56px;
  width: 100%;
  z-index: 50;

  align-items: center;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

:target {
  /* scroll the anchor below the top bar */
  scroll-margin-top: 58px;
}

#header > a {
  font-style: italic;
}

#short-wiki-name {
    display: none;
}

@media all and (max-width: 799px) {
  #short-wiki-name {
    display: inline;
    font-variant-caps: normal;
  }
  #long-wiki-name {
    display: none;
  }
}

#header > .title {
  flex: 1; /* take all available space and push everything else to the right */
  font-size: 140%;
  margin-left: .5em;
  margin-right: .5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

#header-buttons {
  height: 3.5ex;
}

#header-buttons > * {
  margin-left: 0.25em;
}

#feedback-toggle {
  all: unset;
  cursor: pointer;

  background: #f2f2f2;
  color: #024079;

  border: solid 2px #f2f2f2;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 31.5px;

  font-size: 140%;
  font-weight: 900;
  text-align: center;
  transform: scaleX(-1);
}

#feedback-toggle:focus {
  outline-offset: 1px;
  outline-color: #E3AC59;
  outline-width: 1px;
  outline-style: dotted;
}

#feedback-toggle[checked] {
  color: #f2f2f2;
  background: #024079;
}

#header-buttons svg {
  height: 100%;
  vertical-align: middle;
}

#header-buttons svg path {
  fill: #f2f2f2;
}

/* toc {{{ */

#toc-container {
  /* Enlarge the lower padding of the box to give enough space for stay
    above the box when moving to the nav. Otherwise the hover gets lost. */
  margin-bottom: -20px;
  padding-bottom: 20px;
}

#toc-container:hover nav:not([hidden]) {
  display: initial;
}

#toc-container nav {
  display: none;

  background-color: #074980;
  border-color: #dca95a;
  border-radius: 4px;
  border-style: solid;
  border-width: 1.5px;
  box-shadow: 3px 3px 10px #777;
  margin: 0;
  padding: 1ex 1em 1.5ex 1em;

  max-height: 60vh;
  max-width: 80vw;
  overflow-y: auto;
  position: absolute;
  top: 48px;
  z-index: 100;

  font-variant-caps: normal;
  font-size: 75%;
  line-height: 125%;
  letter-spacing: normal;
}

#toc-container nav h1 {
  margin-bottom: 0.6ex;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  font-size: inherit;
  font-weight: 600;
}

#toc-container nav a {
  text-decoration: none;
}

#toc-container nav ul {
  list-style: none;
  margin: 0;
  padding-left: 20px;
}

#toc-container nav ul > li {
  position: relative;
}

#toc-container nav ul > li::before {
  color: #C69854;
  content: "\25cf";
  font-family: WorkSans, monospace;
  font-size: 40%;
  font-variation-settings: normal;
  position: absolute;
  left: -16px;
}

/* }}} toc */

#footer {
  font-family: WorkSans, Arial, sans-serif;
  font-variant-caps: small-caps;
  font-size: 18px;

  text-align: center;
}

#legal img {
  vertical-align: middle;
}

/*
 * Page body
 */
#content {
  box-sizing: border-box;
  max-width: 42em;  /* Fall-back in case min() is not supported */
  max-width: min(100%, 42em);
  padding-left: 3em;
  padding-right: 1em;

  font-family: Crimson ProVariable, Cambria, Palatino, serif;
  font-size: 20px;
  font-variant-numeric: oldstyle-nums;
  font-variation-settings: 'wght' 350; /* make the font slightly more thinner */
  line-height: 125%;
}

/* Headlines {{{ */
#content h1,
#content h2,
#content h3,
#content h4,
#content h5,
#content h6 {
  color: #333;
  font-family: WorkSans, Arial, sans-serif;
  font-weight: normal;
  font-variant-numeric: normal;
  letter-spacing: -0.5px;
}

#content h1,
#content h2,
#content h3 {
  margin-bottom: 1ex;
  margin-top: 1.5ex;
  line-height: 125%;
  padding-left: 0.7em; /* space to place .anchor *inside* this element for hover */

  position: relative;
}

#content h1::before,
#content h2::before,
#content h3::before {
  border-bottom-color: #F4A65B;
  border-bottom-width: 2px;

  position: absolute;
  bottom: -0.2ex;
  left: 0.3em;

  content: "";
  width: 60%;
}

#content h1 {
  font-size: 140%;
  margin-left: -2.1em;
}

#content h1::before {
  border-bottom-style: solid;
}

#content h2 {
  font-size: 130%;
  margin-left: -1.8em;
}

#content h2::before {
  border-bottom-style: dashed;
}

#content h3 {
  font-size: 120%;
  margin-left: -1.5em;
}

#content h3::before {
  border-bottom-style: dotted;
}

#content .anchor {
  display: none;
}

#content h1:hover .anchor,
#content h2:hover .anchor,
#content h3:hover .anchor {
  display: inline;
  float: left;
  font-size: 70%;
  line-height: normal;
  margin-left: -0.7em;
  text-decoration: none;
}

#content h4,
#content h5,
#content h6 {
  font-variant-caps: small-caps;
  font-weight: 300;

  margin-bottom: 0ex;
  margin-top: 1.8ex;
}

#content h4 {
  color: #444;
  font-size: 140%;
}

#content h5 {
  font-size: 120%;
}

#content h6 {
  font-size: 110%;
}

#content :is(h4, h5, h6) + p {
  margin-top: 1ex;
}

/* }}} Headlines */

/* Blockquotes {{{ */

#content blockquote {
  font-family: WorkSans, Arial, sans-serif;
  font-size: 80%;
  font-variation-settings: normal;
  line-height: 140%;

  background-color: #edebdd;
  border-radius: 0.5em;
  border: solid #edebdd 4px;
  margin: 0px 30px 1em 30px;
  padding: 4px 20px;

  position: relative;
}

#content blockquote::before {
  content: "";
  border-left-style: solid;
  border-left-color: #d1cfc7;
  border-left-width: 6px;
  position: absolute;
  left: 6px;
  height: calc(100% - 8px); /* 8px = 4px (border-top) + 4px (border-bottom) */
}

#content blockquote blockquote {
  font-size: inherit;
  margin: 0 0 1em 0;
  border: none;
  padding-top: 0;
  padding-right: 0;
}

#content blockquote blockquote::before {
  height: 100%;
}

#content blockquote p {
  margin-bottom: 0;
}

#content blockquote p:first-child {
  margin-top: 0px;
  padding-top: 6px;
}

#content blockquote pre {
  font-family: inherit;
  margin: 0;
  overflow: auto;
}

/* }}} Blockquotes */

#content p {
  hyphens: auto;
  text-align: justify;
  text-rendering: optimizeLegibility;
}

.hljs {
  /* override the setting in highlight.js' CSS style */
  background: unset;
  padding: unset;
}

/* TODO: ersetzen durch pre.code */
#content > pre {
  /* background-color: #141414; */
  background-color: #23241f;  /* the same as hljs */
  color: #f8f8f2;
  border-color: #545454;
  border-radius: .5em;
  border-width: .25em;
  border-style: solid;
  margin: .5em 30px;
}

#content pre code {
  display: block;

  /* Prevent overflow into the padding on the right side */
  /* https://stackoverflow.com/questions/7711490/prevent-text-from-overflowing-a-padded-container-in-html#15364783 */
  border-right: 1em solid transparent;
  margin: 0 0 0 1em;
  padding: 1em 0;

  overflow-x: auto;
}

#content a {
  color: #1186BD;
  text-decoration-style: dotted;
  text-decoration-thickness: 2px;
}

@keyframes fadeout-bg {
  0% { background-color: #add8e6; }
  100% { background: inherit; }
}

#content :target {
  animation-delay: 1s;
  animation-duration: 2s;
  animation-fill-mode: backwards;
  animation-name: fadeout-bg;
  animation-timing-function: ease-in;
}

#content a:hover,
#content .highlight {
  background-color: #add8e6;
}

#content a code:hover {
  background-color: transparent;
}

#content code {
  font-family: InconsolataVariable, monospace;
  font-size: 90%;
  font-variant-numeric: oldstyle-nums;
  line-height: normal;
}

#content img {
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

#content .audio-wrapper,
#content .video-wrapper {
  /* background-color: darkgray; */
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

#content sup {
  line-height: normal;
}

/* Footnotes/sidenotes {{{ */

#content .footnote-ref {
  font-size: 70%;
  font-variant-numeric: normal;
  line-height: normal;
  padding-left: 1px;
  text-decoration: none;
  vertical-align: super;
}

#content .footnote-ref::before {
  content: "[";
}

#content .footnote-ref::after {
  content: "]";
}

#content .sidenotes {
  clear: right;
  float: right;

  width: 35%;
  margin-right: -35%;

  font-size: 80%;
  line-height: 120%;

  position: relative; /* required for z-index */
  z-index: 100; /* hover above the div on the right side */
}

#content .sidenotes ol {
  margin: 0;
}

#content .sidenotes li {
  list-style-position: inside;
  /* use margin-bottom to create space between two run-together .sidenotes */
  margin-bottom: 1.5ex;
}

#content .sidenotes p {
  margin: 0;
  text-align: left;
  text-indent: 0.7em;
}

#content .sidenotes p:first-child {
  display: inline; /* remove the line-break after li::marker */
}

#content .sidenotes .footnote-backref {
  display: none;
}

#content a.footnote-backref {
  text-decoration: none;
}

/* }}} footnotes/sidenotes */

#content strong {
  font-variation-settings: 'wght' 550;
  font-weight: 550;
}

/* Lists {{{ */

#content ul {
  padding-left: 20px;
  list-style-type: none;
}

#content ul > li {
  position: relative;
}

#content ul > li::before {
  color: #525252;
  content: "\25cf";
  font-family: WorkSans, monospace;
  font-size: 40%;
  font-variation-settings: normal;

  position: absolute;
  left: -16px;
}

#content ul ul > li::before {
  color: #000;
  content: "\2192";
  font-size: 70%;
}

#content ul ul ul > li::before {
  color: #525252;
  font-size: 50%;
  content: "\25c6";
}

/* }}} Lists */

/* Tables {{{ */

#content table {
  margin: 1em auto;
  font-variant-numeric: tabular-nums;
  border-collapse: collapse;
}

/* Draw a thick line on top and on bottom and a thin line between
  thead and tbody and between tbody and tfoot {{{ */

#content table > *:first-child tr:first-child > * {
  border-top: solid 2px;
  padding-top: 4px;
}
#content thead tr:last-child > * {
  padding-bottom: 4px;
}
#content tbody tr:first-child > * {
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 4px;
}
#content tbody tr:last-child > * {
  padding-bottom: 4px;
}
#content tfoot tr:first-child > * {
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 4px;
}
#content table > *:last-child tr:last-child > * {
  border-bottom: solid 2px;
  padding-bottom: 4px;
}
/* }}} */

#content tr > * {
  padding: 0px .7em;
}
#content tr > *:first-child {
  padding-left: 4px;
}
#content tr > *:last-child {
  padding-right: 4px;
}

#content thead th {
  font-style: italic;
  text-align: center;
}

#content tbody tr:hover {
  background-color: lightblue;
}

/* }}} Tables */

#content .deepl-translation {
  font-size: smaller;
  font-style: italic;
}

#content blockquote + .deepl-translation {
  display: block;
  text-align: right;
  margin: -0.8em 30px 0.8em 0;
}
